import { Teleport } from "vue";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaToastContainer } from "@/components/general/GaToast";
import { GaModalContainer } from "@/components/general/GaModal";

export function GaTopLayer(): GaVueComponent {
  return (
    <Teleport to="body">
      <GaToastContainer />
      <GaModalContainer />
    </Teleport>
  );
}
