import { getHttpClient } from "@utils/httpClient";

export type CountryListView = {
  countryCode: string;
  countryName: string;
};

export async function getCountryListViews(): Promise<CountryListView[]> {
  return await getHttpClient()
    .get<CountryListView[]>("/gaapi/countries")
    .then((response) => {
      return response.data;
    });
}
