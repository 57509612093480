import type { GaVueComponent } from "@/common/vueUtils";
import { createStore, type Store } from "@/common/storeUtils";
import { GaButton } from "@/components/general/GaButton";
import { useI18n } from "@/i18n/i18nSetup";
import "./GaModal.scss";
import { onKeyUp } from "@vueuse/core/index";
import { UseFocusTrap } from "@vueuse/integrations/useFocusTrap/component";
import { TransitionGroup } from "vue";

export type ConfirmModalConfig = {
  title?: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
};

type ConfirmModalState = {
  kind: "CONFIRM_MODAL";
  title: string | null;
  body: () => GaVueComponent;
  footer: () => GaVueComponent;
  close: () => void;
};

function afterEachChange(newState: ConfirmModalState | null): void {
  const backdropClass = "modal-open";
  const body = document.querySelector("body");
  if (newState === null) {
    body?.classList.remove(backdropClass);
  } else {
    body?.classList.add(backdropClass);
  }
}

namespace ConfirmModal {
  export function open(store: Store<ConfirmModalState | null>, newState: ConfirmModalState): void {
    store.set(newState);
    afterEachChange(newState);
  }

  export function close(store: Store<ConfirmModalState | null>): void {
    store.set(null);
    afterEachChange(null);
  }
}

const store = createStore<ConfirmModalState | null>(null);

export function useConfirmationModal(): (config: ConfirmModalConfig) => Promise<boolean> {
  const { t } = useI18n();
  return (config) => {
    return new Promise((resolve) => {
      const title = config.title === undefined || config.title.trim().length === 0 ? null : config.title;
      const cancelLabel = config.cancelLabel === undefined || config.cancelLabel.trim().length === 0 ? null : config.cancelLabel;
      const confirmLabel = config.confirmLabel === undefined || config.confirmLabel.trim().length === 0 ? null : config.confirmLabel;

      function cancel(): void {
        ConfirmModal.close(store);
        resolve(false);
      }

      function confirm(): void {
        ConfirmModal.close(store);
        resolve(true);
      }

      ConfirmModal.open(store, {
        kind: "CONFIRM_MODAL",
        title,
        body: () => <>{config.message}</>,
        footer: () => (
          <>
            <GaButton onClick={cancel} variant="primary-alt">
              {cancelLabel ?? t("general:button.cancel")}
            </GaButton>
            <GaButton onClick={confirm}>{confirmLabel ?? t("general:button.confirm")}</GaButton>
          </>
        ),
        close: cancel,
      });
    });
  };
}

function GaConfirmationModal(props: { state: ConfirmModalState }): GaVueComponent {
  const labelId = "modalLiveLabel";
  const labelledby = props.state.title !== null ? labelId : undefined;

  onKeyUp("Escape", () => props.state.close());

  return (
    <div class="modal d-block" tabindex="-1" aria-modal="true" role="dialog" aria-labelledby={labelledby}>
      <div class="modal-dialog">
        <UseFocusTrap
          options={{
            clickOutsideDeactivates: () => {
              props.state.close();
              return true;
            },
          }}>
          <div class="modal-content">
            <div class="modal-header">
              {props.state.title !== null ? (
                <h5 class="modal-title" id={labelId}>
                  {props.state.title}
                </h5>
              ) : null}
              <button onClick={props.state.close} type="button" class="btn-close" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>{props.state.body()}</p>
            </div>
            <div class="modal-footer">{props.state.footer()}</div>
          </div>
        </UseFocusTrap>
      </div>
    </div>
  );
}

export function GaModalContainer(): GaVueComponent {
  const unpackedStore = store.unpackNull();
  return (
    <TransitionGroup name="modal">
      {unpackedStore !== null ? (
        <>
          <GaConfirmationModal state={unpackedStore.get()} key="modal" />
          <div class="modal-backdrop" key="modal-backdrop"></div>
        </>
      ) : null}
    </TransitionGroup>
  );
}
