import type { GaVueComponent } from "@/common/vueUtils";
import { fromMarkdown } from "mdast-util-from-markdown";
import type { Root } from "mdast-util-from-markdown/lib";
import type { DefaultRenderer, IntermediateRenderer, Renderer } from "@/common/rendererUtils";
import { createRenderer } from "@/common/rendererUtils";
import { h } from "vue";

export type MarkdownNode = Root["children"][number];

function parseMarkdown(input: string): MarkdownNode[] {
  return fromMarkdown(input).children;
}

/**
 * MDAST creates `paragraph` nodes for each `listItem`.
 * As we do not want to render those texts as HTML paragraphs (inside <li> elements), this unwraps the `paragraph`.
 */
function unwrapParagraphs(nodes: MarkdownNode[]): MarkdownNode[] {
  return nodes.flatMap((node) => {
    if (node.type === "paragraph") {
      return node.children;
    }
    return [node];
  });
}

function renderMarkdownUnsafe(node: MarkdownNode, renderer: Renderer<MarkdownNode>): GaVueComponent {
  switch (node.type) {
    case "break":
      return <br />;
    case "blockquote": {
      const children = renderer(node.children);
      return <blockquote>{...children}</blockquote>;
    }
    case "code":
      return <code>{node.value}</code>;
    case "definition":
      return <></>;
    case "delete": {
      // this case is a GitHub Flavored Markdown, which we don't support
      return <></>;
    }
    case "emphasis": {
      const children = renderer(node.children);
      return <em>{...children}</em>;
    }
    case "footnoteDefinition": {
      // this case is a GitHub Flavored Markdown, which we don't support
      return <></>;
    }
    case "footnoteReference": {
      // this case is a GitHub Flavored Markdown, which we don't support
      return <></>;
    }
    case "heading": {
      const children = renderer(node.children);
      return h(`h${node.depth}`, {}, children);
    }
    case "html":
      return <>{node.value}</>;
    case "image":
      return <img src={node.url} alt={node.alt ?? undefined} />;
    case "imageReference":
      return <></>;
    case "inlineCode":
      return <code>{node.value}</code>;
    case "link": {
      const children = renderer(node.children);
      return <a href={node.url}>{...children}</a>;
    }
    case "linkReference":
      return <></>;
    case "list": {
      const children = renderer(node.children);
      return node.ordered === true ? <ol>{...children}</ol> : <ul>{...children}</ul>;
    }
    case "listItem": {
      const children = renderer(unwrapParagraphs(node.children));
      return <li>{...children}</li>;
    }
    case "paragraph": {
      const children = renderer(node.children);
      return <p>{...children}</p>;
    }
    case "strong": {
      const children = renderer(node.children);
      return <strong>{...children}</strong>;
    }
    case "table": {
      // this case is a GitHub Flavored Markdown, which we don't support
      return <></>;
    }
    case "tableCell": {
      // this case is a GitHub Flavored Markdown, which we don't support
      return <></>;
    }
    case "tableRow": {
      // this case is a GitHub Flavored Markdown, which we don't support
      return <></>;
    }
    case "text":
      return <>{node.value}</>;
    case "thematicBreak":
      return <hr />;
    case "yaml": {
      // this case is only used with frontmatter, which we don't support
      return <></>;
    }
  }
}

export function useUnsafeMarkdownRenderer(): DefaultRenderer<MarkdownNode> {
  return renderMarkdownUnsafe;
}

const unsafeNodeTypes = ["link", "image", "inlineCode", "code", "html"] satisfies MarkdownNode["type"][];
type UnsafeNode = MarkdownNode & { type: (typeof unsafeNodeTypes)[number] };

export function useSafeMarkdownRenderer(unsafeHandler: (unsafeNode: UnsafeNode, renderer: Renderer<MarkdownNode>) => GaVueComponent): DefaultRenderer<MarkdownNode> {
  return (node, renderer) => {
    for (const unsafeType of unsafeNodeTypes) {
      if (node.type === unsafeType) {
        return unsafeHandler(node, renderer);
      }
    }
    return renderMarkdownUnsafe(node, renderer);
  };
}

export function GaMarkdownContent(props: { input: string; defaultRenderer: DefaultRenderer<MarkdownNode>; intermediateRenderers?: IntermediateRenderer<MarkdownNode>[] }): GaVueComponent {
  const renderer = createRenderer(props.defaultRenderer, ...(props.intermediateRenderers ?? []));
  return <>{renderer(parseMarkdown(props.input))}</>;
}
