import type { GaVueComponent } from "@/common/vueUtils";
import type { WithOptional } from "@utils/formUtils";
import type { Store } from "@/common/storeUtils";
import type { DeepReadonly } from "vue";

type Props = {
  checked: boolean;
  onUpdateChecked: (newValue: boolean) => void;
  label?: string;
  name: string;
  help?: string;
  disabled: boolean;
  required: boolean;
  switchStyle: boolean;
};

type PropsWithOptional = WithOptional<Props, "disabled" | "required" | "switchStyle">;

function addDefaults(props: PropsWithOptional): Props {
  return {
    ...props,
    disabled: props.disabled ?? false,
    required: props.required ?? false,
    switchStyle: props.switchStyle ?? false,
  };
}

export function GaFormFieldInputCheckbox(p: PropsWithOptional): GaVueComponent {
  const props: Props = addDefaults(p);

  return (
    <div class={props.switchStyle ? "form-check form-switch" : "form-check"}>
      <input
        type="checkbox"
        checked={props.checked}
        value="true"
        onInput={(e) => props.onUpdateChecked((e.target as HTMLInputElement).checked)}
        name={props.name}
        id={props.name}
        required={props.required}
        disabled={props.disabled}
        class={"form-check-input"}
      />
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class={"form-check-label"}>
          {props.label} {props.required ? <span>*</span> : null}
        </label>
      ) : null}
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}

// WIP: please do not use yet
export function GaFormFieldInputCheckboxExperimental(props: {
  store: Store<boolean>;
  onBlur?: () => void;
  labelHtml?: string; // TODO: Ugly for now, find general solution
  name: string;
  help?: string;
  disabled?: boolean;
  required?: boolean;
  switchStyle?: boolean;
  errors?: DeepReadonly<string[]>;
}): GaVueComponent {
  const errors = props.errors ?? [];
  return (
    <div
      class={{
        "form-check": true,
        "form-switch": props.switchStyle === true,
      }}>
      <input
        class={{
          "form-check-input": true,
          "is-invalid": errors.length > 0,
        }}
        type="checkbox"
        checked={props.store.get()}
        value="true"
        onInput={(e) => {
          props.store.set((e.target as HTMLInputElement).checked);
          props.onBlur?.(); // TODO: Ugly for now, nicer solution. Exception for checkboxes for validation purposes?
        }}
        onBlur={props.onBlur}
        name={props.name}
        id={props.name}
        required={props.required}
        disabled={props.disabled}
      />
      {props.labelHtml !== undefined && props.labelHtml.trim().length > 0 ? (
        <label for={props.name} class="form-check-label" vHtml={props.labelHtml}>
          {props.labelHtml} {props.required === true ? <span>*</span> : null}
        </label>
      ) : null}
      {errors.map((error) => (
        <div class="invalid-feedback">{error}</div>
      ))}
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}
