import type { GaVueComponent } from "@/common/vueUtils";
import { TransitionGroup } from "vue";
import { createStore, type Store } from "@/common/storeUtils";
import type { ToastState, ToastVariant } from "@newgenerated/shared/schema";
import "./GaToast.scss";

export type ToastConfig = {
  title?: string;
  message: string;
  variant?: ToastVariant;
  autohide?: boolean;
  delay?: number;
};

function createToast(config: ToastConfig): ToastState {
  const title = config.title === undefined || config.title.trim().length === 0 ? null : config.title;
  return {
    uuid: crypto.randomUUID(),
    message: config.message,
    title: title,
    variant: config.variant ?? "primary",
  };
}

namespace Toast {
  export function remove(self: Store<ToastState[]>, uuid: string): void {
    self.set(self.get().filter((toast) => toast.uuid !== uuid));
  }

  export function add(self: Store<ToastState[]>, config: ToastConfig): void {
    const toast = createToast(config);
    if (config.autohide !== false) {
      const delay = config.delay ?? 5000;
      setTimeout(() => remove(self, toast.uuid), delay);
    }
    self.set([...self.get(), toast]);
  }
}

const store = createStore<ToastState[]>([]);

export const addToast = (config: ToastConfig): void => Toast.add(store, config);

function GaToast(props: { state: ToastState; onClose: () => void }): GaVueComponent {
  const { variant } = props.state;
  const assertive = variant === "danger" || variant === "warning";
  const toastClasses = ["toast", "show"];
  const closeClassesWithoutTitle = ["btn-close", "me-2", "m-auto"];
  if (variant === "primary") {
    toastClasses.push("bg-primary", "text-white");
    closeClassesWithoutTitle.push("btn-close-white");
  } else if (variant === "success") {
    toastClasses.push("bg-success", "text-black");
  } else if (variant === "warning") {
    toastClasses.push("bg-warning", "text-black");
  } else {
    toastClasses.push("bg-danger", "text-white");
    closeClassesWithoutTitle.push("btn-close-white");
  }

  return props.state.title === null ? (
    <div class={toastClasses} role={assertive ? "alert" : "status"} aria-live={assertive ? "assertive" : "polite"} aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">{props.state.message}</div>
        <button type="button" class={closeClassesWithoutTitle} aria-label="Close" onClick={props.onClose}></button>
      </div>
    </div>
  ) : (
    <div class={toastClasses} role={assertive ? "alert" : "status"} aria-live={assertive ? "assertive" : "polite"} aria-atomic="true">
      <div class="toast-header">
        <strong class="me-auto">{props.state.title}</strong>
        <button type="button" class="btn-close" aria-label="Close" onClick={props.onClose}></button>
      </div>
      <div class="toast-body">{props.state.message}</div>
    </div>
  );
}

export function GaToastContainer(): GaVueComponent {
  return (
    <div class="toast-container" aria-live="polite" aria-atomic="true">
      <TransitionGroup name="toast-list">
        {store.get().map((state) => (
          <GaToast state={state} onClose={() => Toast.remove(store, state.uuid)} key={state.uuid} />
        ))}
      </TransitionGroup>
    </div>
  );
}
