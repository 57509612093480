import type { DeepReadonly } from "vue";

export type Result<T> = { state: "ERROR"; msg: string } | { state: "SUCCESS"; value: T } | { state: "IGNORE" };

export async function saveAsyncRequest<T, V extends unknown[]>(call: (...params: DeepReadonly<V>) => Promise<T>, isLastRequest: boolean | ((...params: DeepReadonly<V>) => boolean), ...params: DeepReadonly<V>): Promise<Result<T>> {
  const checkIsLastRequest = (isLastRequest: boolean | ((...params: DeepReadonly<V>) => boolean), ...params: DeepReadonly<V>): boolean => {
    if (typeof isLastRequest === "boolean") {
      return isLastRequest;
    }
    return isLastRequest(...params);
  };
  try {
    const response = await call(...params);
    if (checkIsLastRequest(isLastRequest, ...params)) {
      return { state: "SUCCESS", value: response };
    }
    return { state: "IGNORE" };
  } catch (e) {
    if (checkIsLastRequest(isLastRequest, ...params)) {
      return { state: "ERROR", msg: e instanceof Error ? e.message : "" };
    }
    return { state: "IGNORE" };
  }
}
